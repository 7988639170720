import React from "react"
import { css } from "@emotion/core"

import NavLink from "./navLink"

export default () => (
  <div
    css={css`
      max-width: max-content;
      margin: 0 auto;
    `}
  >
    <NavLink to="/" text="Home" />
    <NavLink to="/testimonials/" text="Testimonials" />
    <NavLink to="/contact/" text="Contact" />
  </div>
)