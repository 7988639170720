import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

export default ({ to, text }) => {

  const [isCurrentLink, setIsCurrentLink] = useState(false);

  useEffect(() => {
    if(window.location.pathname === to) {
      setIsCurrentLink(true);
    }
  }, [])

  return (
    <Link
      to={to}

      css={css`
        display: inline-block;
        text-decoration: none;
        margin-right: 1rem;
        color: var(--text-lighter);
        letter-spacing: .2px;
        ${isCurrentLink ? "color: var(--text); font-weight: 500;" : ""}
        transition: color .1s;

        :last-of-type {
          margin-right: 0;
        }
      `}
    >
      {text}
    </Link>
  )
}