import React from "react"
import { Link } from "gatsby"
import { css, Global } from "@emotion/core"

import Nav from "./nav"

import basics from '../../ben-edit/basics'

const colors = require("../util/colors.js");

export default ({ children, noWidth }) => {
  const title = basics.site_name

  let cssVars = ``;

  for(const color in colors) {
    cssVars += `--${color}: ${colors[color]};`
  }

  return (
    <React.Fragment>
      <Global
        styles={css`
          :root {
            ${cssVars}
          }

          * {
            font-family: Inter, system-ui, sans-serif;
            font-weight: 400;
            font-size: 1rem;
            color: var(--text);
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          body {
            background-color: var(--background);
          }

          ::selection {
            background-color: white;
            color: var(--background);
          }
        `}
      />

      <div
        css={css`
          ${!noWidth ? "max-width: 768px;" : ""}
          padding: 1.5rem;
          padding-top: 3rem;
          margin: 0 auto;
        `}
      >

        <header
          css={css`
            margin-bottom: 2rem;
          `}
        >
          <Link
            to="/"
            css={css`
              display: block;
              text-decoration: none;
              max-width: max-content;
              margin: 0 auto;
              font-weight: 900;
              font-size: 36px;
              margin-bottom: .5rem;
              letter-spacing: -.2px;
              text-align: center;
            `}
          >
            {title}
          </Link>

          <Nav />
        </header>

        {children}
      </div>

    </React.Fragment>
  )
}
