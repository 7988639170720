import React from "react"
import { Helmet } from "react-helmet"

import basics from '../../ben-edit/basics'

export default props => {
  const defaultTitle = basics.site_name
  const defaultDescription = basics.subtitle

  const title = props.title ? `${props.title} - ${defaultTitle}` : defaultTitle;
  const description = props.description || defaultDescription;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="shortcut icon" href="https://emojicdn.elk.sh/🎬" />

      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />

      <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    </Helmet>
  )
}
